<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改卡面管理' : '新建卡面管理'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="等级名称:" name="name">
            <a-input
              v-model:value="form.name"
              placeholder="请输入等级名称"
              allow-clear
            >
            </a-input>
            <div style="color: #999999">例如:大众会员、黄金会员、铂金会员、钻石会员</div>
          </a-form-item>
          <a-form-item label="等级权重:" name="weight">
            <a-select
              allow-clear
              placeholder="请选择等级权重"
              v-model:value="form.weight"
            >
              <a-select-option
                v-for="item in weightList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
            <div style="color: #999999">会员等级的权重，数字越大 等级越高</div>
          </a-form-item>
          <a-form-item label="升级条件:" name="upgrade">
            <a-input
              v-model:value="form.upgrade"
              placeholder="请输入升级条件"
              allow-clear
            >
              <template #addonBefore>实际消费金额满</template>
              <template #addonAfter>元</template>
            </a-input>
            <div style="color: #999999">用户的实际消费金额满足后，自动升级</div>
          </a-form-item>
          <a-form-item label="等级权益:" name="equity">
            <a-input
              v-model:value="form.equity"
              placeholder="请输入等级权益"
              allow-clear
            >
              <template #addonBefore>折扣率</template>
              <template #addonAfter>折</template>
            </a-input>
            <div style="color: #999999">折扣率范围0.0-9.9，例: 9.8代表98折，0代表不折扣</div>
          </a-form-item>
          <a-form-item label="状态:" name="status">
            <a-radio-group v-model:value="form.status" @change="statusChange">
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="0">禁用</a-radio>
            </a-radio-group>
          </a-form-item>
          <!-- <a-form-item label="等级权益:" name="equity">
            <a-input-number
              v-model:value="form.name"
              placeholder="请输入等级权益"
              allow-clear
            >
            </a-input-number>
            <div style="color: #999999">折扣率范围0.0-9.9，例: 9.8代表98折，0代表不折扣</div>
          </a-form-item> -->
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
// import { PlusOutlined } from '@ant-design/icons-vue'
// import * as pointsCategoryApi from '@/api/jyyw/pointsCategory'
import * as corpGradeApi from '@/api/corp/corpGrade'
import * as dictDataApi from '@/api/sys/dictData'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'categoryEdit',
  components: {
    // PlusOutlined
  },
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    corpId: Number
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      imgList: [],
      logoList: [],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {
        name: [
          {
            required: true,
            message: '请输入等级名称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        weight: [
          {
            required: true,
            message: '请选择等级权重',
            type: 'number',
            trigger: 'blur'
          }
        ],
        upgrade: [
          {
            required: true,
            message: '请输入升级条件',
            type: 'string',
            trigger: 'blur'
          }
        ],
        equity: [
          {
            required: true,
            message: '请输入等级权益',
            type: 'string',
            trigger: 'blur'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            type: 'number',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      // 状态字典项
      stateDict: [],
      weightList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryDataStates()
  },
  methods: {
    statusChange(e) {
      this.form.status = e.target.value
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          console.log('this.form168', this.form)
          if (this.corpId) {
            this.form.corpId = this.corpId
          }
          corpGradeApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 等级权重字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('gradeWeight')
        .then((res) => {
          if (res.code === 0) {
            this.weightList = res.data
            this.weightList.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
              console.log('item.dictDataCode', typeof item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.colorStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .colorStyle-sub {
    width: 35px;
    height: 35px;
    border-radius: 6px;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    // background: #40a9ff;
    .icon {
      position: absolute;
      color: #fff;
    }
  }
}
</style>
